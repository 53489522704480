<template>
  <div class="basket-item">
    <div class="basket-item__inner">
      <div class="basket-item__content">
        <span
            class="basket-item__delete"
            @click.prevent="deleteProduct(basketItem.specificId)"
        ></span>
        <router-link :to="productLink"
                     class="basket-item__img">
          <img :src="$imgPlaceholder"
               :data-src="product.image_xs"
               class="lazyload"
               :alt="product.name"/>
        </router-link>

        <div class="basket-item__title">
          <router-link
              :to="productLink">
            {{ product.name }}
          </router-link>
        </div>
      </div>
      <div class="basket-item__price">
        <div class="basket-item__price-item">
          <div class="basket-item__price-val" :class="{_discount: categoryDiscount}">
            {{ oneCurrentBasketItemPrice - categoryDiscountValue | formatPrice }} {{ $t('грн') }}
            <template v-if="categoryDiscount">*</template>
          </div>
          <div class="basket-item__price-val _small" v-if="basketItem.variant && basketItem.variant.packing_price">
            {{ $t('Пакування') }}: <b>{{ basketItem.variant.packing_price | formatPrice }}
            {{ $t('грн') }}</b></div>
        </div>
        <div class="basket-item__price-item _quantity">
          <Quantity class="_small"
                    :value="basketItem.quantity"
                    @input="onChangeProductQuantity"
          />
        </div>
        <div class="basket-item__price-item _total">
          <div class="basket-item__price-val">
            <span class="d-none d-sm-inline">{{ $t('Загалом') }}:&nbsp;</span>{{
              ((oneCurrentBasketItemPrice - categoryDiscountValue) + basketItem.variant.packing_price) * basketItem.quantity | formatPrice
            }} {{ $t('грн') }}
          </div>
        </div>
      </div>
    </div>

    <div class="basket-item__description-wrap">
      <div class="basket-item__description">
        <div v-if="categoryDiscount"
             class="basket-item__description-row _discount">
          *&nbsp;<span>-{{ categoryDiscount }}%
          {{ $t('на товари з категорії') }} {{ product.category.name }} ({{ $t('з') }}
          {{ product.category.discount_from }}
          {{ $t('до') }} {{ product.category.discount_to }}<template v-if="discountDays.length < 7">; {{ $t('в') }}
            <span v-for="(day, index) in discountDays"
                  :key="index">
              {{ getDayName(day) }}<template v-if="index < discountDays.length - 1">,</template>
            </span></template>)
        </span>
        </div>
        <div v-if="$get(basketItem.product, 'variants', []).length > 1 && basketItem.variant.name"
             class="basket-item__description-row">
          <b>{{ $t('Варіант') }}:</b>
          &nbsp;<span>{{ basketItem.variant.name }}</span>
        </div>
        <div v-if="basketItem.property"
             class="basket-item__description-row">
          <b>{{ $t('Соус') }}:</b>&nbsp;
          <span>{{ basketItem.property.name }}</span>
        </div>
        <div v-if="basketItem.additional_ingredients && Object.keys(basketItem.additional_ingredients).length"
             class="basket-item__description-row">
          <b>{{ $t('Додаткові інгредієнти') }}:</b>&nbsp;
          <AdditionalTags inline
                          :ingredients="basketItem.additional_ingredients"/>
        </div>
        <div v-if="basketItem.comment"
             class="basket-item__description-row">
          <b>{{ $t('Коментар') }}:</b>&nbsp;
          <span>{{ basketItem.comment }}</span>
        </div>
      </div>
<!--      <span v-for="item in product.category.pickup_restaurants" :key="item.id">{{item.name}},</span>-->
      <BasketItemLabels :basketItem="basketItem" :isSelfPicking="isSelfPicking"/>

    </div>
  </div>
</template>

<script>
  import {mapMutations, mapState} from 'vuex'
  import getBasketItemPrice from '@/helpers/getBasketItemPrice'
  import getDayName from '@/helpers/getDayName'
  import Quantity from './Quantity'
  import AdditionalTags from '@/components/AdditionalTags'
  import BasketItemLabels from '@/components/BasketItemLabels'
  import getCategoryDiscountPercent from '@/helpers/getCategoryDiscountPercent'
  import getDiscountValue from '@/helpers/getDiscountValue'

  export default {
    name: 'basket-item',
    components: {
      AdditionalTags,
      Quantity,
      BasketItemLabels
    },
    props: {
      basketItem: {
        type: Object,
        required: true,
      },
      selfPickingDiscountPercent: {
        type: Number,
        default: 0
      },
      selectedTime: null,
      isSelfPicking: Boolean
    },
    data(vm) {
      return {
        product: vm.basketItem.product,
        getDayName
      }
    },
    computed: {
      ...mapState('settings', ['settings']),
      oneCurrentBasketItemPrice() {
        return getBasketItemPrice(this.basketItem)
      },
      productLink() {
        return {name: 'product', params: {id: this.product.slug ? this.product.slug : this.product.id}}
      },
      categoryDiscount() {
        let categoryDiscountPercent = getCategoryDiscountPercent(this.product.category, this.selectedTime)
        if (this.selfPickingDiscountPercent > categoryDiscountPercent) {
          return 0
        }
        return categoryDiscountPercent
      },
      discountDays() {
        let days = this.$get(this.basketItem.product.category, 'discount_days', [])
        if (days[0] === 0) {
          days.splice(0, 1)
          days.push(0)
        }
        return days
      },
      categoryDiscountValue() {
        return getDiscountValue(this.oneCurrentBasketItemPrice, this.categoryDiscount)
      },
    },
    methods: {
      ...mapMutations('basket', ['deleteProduct', 'changeProductQuantity']),
      onChangeProductQuantity(value) {
        this.changeProductQuantity({
          specificId: this.basketItem.specificId,
          newQuantity: value,
        })
      },
    },
  }
</script>

