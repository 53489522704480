<template>
  <div class="basket-message">
    <BasePreloader v-if="pending" style="top: -2rem" />
    <div class="basket-message__title">Ви не зареєстровані?</div>
    <div class="basket-message__text text">
      Для того, щоб зберегти ваше замовлення, а також використовувати весь функціонал сайту - потрібно
      <strong>зареєструватися</strong> або
      <strong>увійти</strong> в систему.
    </div>
    <div class="basket-message__footer">
      <button @click.prevent="onLoginClick" class="basket-message__btn base-btn">Увійти</button>
      <button
        @click.prevent="onRegistrationClick"
        class="basket-message__btn base-btn _secondary"
      >Реєстрація</button>
      <button @click.prevent="closeModal" class="basket-message__btn base-btn _secondary">Пропустити</button>
    </div>
  </div>
</template>

<script>
import formMixins from "../mixins/formMixins";

export default {
  mixins: [formMixins],
  methods: {
    closeModal() {
      this.$vModal.close();
    },
    onLoginClick() {
      this.$vModal.close();
      this.$vModal.open("auth-login");
    },
    onRegistrationClick() {
      this.$vModal.close();
      this.$vModal.open("auth-registration");
    },
  },
};
</script>

<style lang="less">
</style>
