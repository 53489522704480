<template>
  <transition :duration="1000"
              @before-enter="beforeEnter"
              @enter="enter"
              @after-enter="afterEnter"
              @before-leave="beforeLeave"
              @leave="leave"
              :class="isOpen ? '_open' : ''">
    <div class="alert" v-if="isOpen" :class="isOpen ? '_open' : ''">
      <ul v-if="formattedValue" class="alert__list">
        <slot name="li" :formattedValue="formattedValue">
          <li v-for="(item, i) in formattedValue"
              :key="i"
              class="alert__list-item">
            {{item}}
          </li>
        </slot>
      </ul>
    </div>
  </transition>
</template>

<script>
  import getTransitionDuration from '../helpers/getTransitionDuration'

  export default {
    props: {
      value: {
        default: null
      }
    },
    data() {
      return {}
    },
    computed: {
      formattedValue() {
        if (this.value instanceof Error) return [this.value.message]
        if (Array.isArray(this.value)) return this.value
        if (this.value) return [this.value]
        return null
      },
      isOpen() {
        return !!this.formattedValue
      }
    },

    methods: {
      beforeEnter: function (el) {
        el.style.height = '0px'
        el.style.margin = '0px'
      },
      enter: function (el, done) {
        el.style.height = el.scrollHeight - el.clientHeight + el.offsetHeight + 'px'
        el.style.margin = null

        setTimeout(function () {
          done()
        }, getTransitionDuration(el))
      },
      afterEnter: function (el) {
        el.style.height = null
      },
      beforeLeave: function (el) {
        el.style.height = el.offsetHeight + 'px'
      },
      leave: function (el, done) {
        el.style.height = '0px'
        el.style.margin = '0px'
        setTimeout(function () {
          done()
        }, getTransitionDuration(el))
      }
    }
  }
</script>

<style lang="less">

</style>
