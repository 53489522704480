<template>
  <div class="additional-tags" v-if="!inline">
    <transition-group tag="div" name="list" class="additional-tags__list">
      <div class="additional-tags__item"
           v-for="item in ingredients"
           :key="item.ingredient.id"
      >
        <span>{{ item.ingredient.name.trim() }}</span>
        <span class="additional-tags__item-price" v-if="!isMain">,&nbsp;<b>{{ item.ingredient.price | formatPrice }} {{ $t('грн') }}</b>&nbsp;</span>
        <span class="additional-tags__item-count">&nbsp;х {{ item.quantity }}</span>
        <button type="button"
                class="additional-tags__item-delete"
                @click="onDelete(item)">
          <BaseSvg name="ico-close"/>
        </button>
      </div>
    </transition-group>
  </div>
  <div v-else class="additional-tags _inline">
    <span v-for="item in ingredients"
          :key="item.ingredient.id">{{ item.ingredient.name.trim() }} ({{ item.ingredient.price | formatPrice }} {{ $t('грн') }} х {{ item.quantity }})</span>
  </div>
</template>

<script>
  export default {
    name: 'AdditionalTags',
    props: {
      ingredients: null,
      inline: {
        type: Boolean,
        default: false
      },
      isMain: Boolean
    },
    methods: {
      onDelete(ingredient) {
        this.$emit('delete', ingredient)
      }
    }
  }
</script>

<style>

</style>
