<template>
  <div class="">
    <div class="basket container">
      <div class="container-inner">
        <div class="basket__not-product" v-if="basket.length === 0">
          <Gifts v-if="gifted_products"
                 v-model="inputs.gift_product.value"
                 disabled
                 :gifted-products="gifted_products"/>
          <BaseSvg class="basket__not-product-ico" name="ico-cart"/>
          <h4 class="basket__not-product-title">{{ $t('Кошик порожній') }}</h4>
          <p class="basket__not-product-text">
            {{ $t('Ви нічого не додали у кошик, перейдіть у розділ меню') }}
          </p>
          <router-link :to="{
                      name: 'home',
                      hash: '#catalog'
                    }" class="basket__not-product-btn base-btn">{{ $t('До меню') }}
          </router-link>
        </div>

        <template v-else>
          <h1 class="basket__title">{{ $t('Оформлення замовлення') }}</h1>
          <div class="basket__wrap">
            <BasePreloader v-if="pendingSend"/>
            <div class="basket__form">
              <BasePreloader v-if="pendingLoad || pendingLastOrder"/>
              <FormBasket :inputs="inputs"
                          @input="setInput"
                          v-if="settings"
                          ref="formBasket"
              />
              <AlertInline :value="overallErrorsMix" class="basket__error  _warning"/>
            </div>
            <div class="basket__content">
              <Gifts v-if="gifted_products"
                     v-model="inputs.gift_product.value"
                     :disabled="disabledGifts"
                     :gifted-products="gifted_products"/>
              <BasePreloader v-if="pendingLoad || pendingLastOrder"/>
              <div class="basket__product-list product-list">
                <div class="product-list__header d-sm-none">
                  <div class="product-list__header-col">{{ $t('Товар') }}</div>
                  <div class="product-list__header-col">{{ $t('Загалом') }}</div>
                </div>
                <div class="product-list__body">
                  <BasketItem
                      v-for="basketItem in basket"
                      :key="basketItem.specificId"
                      :basketItem="basketItem"
                      :selfPickingDiscountPercent="selfPickingDiscountPercent"
                      :selectedTime="selectedTime"
                      :isSelfPicking="showByDelivery([DELIVERY_METHOD_SELF])"
                      class="product-list__item"
                  />
                </div>
                <div class="product-list__footer">
                  <transition name="fade">
                    <div v-if="withOil" class="product-list__param">
                      <BaseCheckbox v-model="inputs.add_oil.value">
                        <span class="checkbox__text">
                          <BaseSvg class="checkbox__icon"
                                   name="ico-oil"
                          />
                          {{ $t('Гостра олійка') }}
                        </span>
                      </BaseCheckbox>
                    </div>
                  </transition>
                  <transition name="fade">
                    <div v-if="bonuses"
                         class="product-list__param">
                      <BaseCheckbox :disabled="disabledBonuses"
                                    v-model="inputs.use_bonuses.value"
                      >
                        <span class="checkbox__text">
                          <BaseSvg
                              class="checkbox__icon"
                              name="ico-gift"
                          />
                          {{ $t('Використати бонуси') }}:
                          <b>{{ usedBonuses | formatPrice }} {{ $t('грн') }}
                            <span class="product-list__bonus-from">
                              ({{ $t('з') }} {{ bonuses | formatPrice }} {{ $t('грн') }})
                            </span>
                          </b>
                        </span>
                      </BaseCheckbox>
                      <span class="product-list__param-error">*
                        {{ $t('Бонусів має вистачити на оплату повного замовлення!') }}</span>
                    </div>
                  </transition>
                  <div class="product-list__footer-content">
                    <div class="product-list__footer-item">
                      <span class="product-list__footer-item-label">{{ $t('Товари') }}:</span>
                      <span class="product-list__footer-item-value">{{ productsPriceForShow | formatPrice }} {{
                          $t('грн')
                        }}</span>
                    </div>
                    <div class="product-list__footer-item" v-if="totalPackingPrice">
                      <span class="product-list__footer-item-label">{{ $t('Пакування') }}:</span>
                      <span class="product-list__footer-item-value">{{ totalPackingPrice | formatPrice }} {{
                          $t('грн')
                        }}</span>
                    </div>
                    <div class="product-list__footer-item">
                      <span class="product-list__footer-item-label">{{ $t('Доставка') }}:</span>
                      <span class="product-list__footer-item-value">{{ deliveryPrice | formatPrice }} {{
                          $t('грн')
                        }}</span>
                    </div>
                    <transition name="fade">
                      <div class="product-list__footer-item" v-if="selfPickingDiscountValue">
                        <span class="product-list__footer-item-label">{{ $t('Знижка при самовивозі') }}
                          {{ selfPickingDiscountPercent }}%:
                          <i>* {{ $t('Застосовується до товарів без наявних знижок') }}</i>
                        </span>
                        <span class="product-list__footer-item-value">-{{ selfPickingDiscountValue | formatPrice }} {{
                            $t('грн')
                          }}</span>
                      </div>
                    </transition>
                    <div class="product-list__footer-item _total">
                      <span class="product-list__footer-item-label">{{ $t('Разом до оплати') }}:</span>
                      <span class="product-list__footer-item-value">{{ totalPrice | formatPrice }} {{
                          $t('грн')
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="basket__details basket-details" v-if="false">
                <label class="basket-details__label">{{ $t('Деталі') }}</label>
                <BaseCheckbox
                    v-model="inputs.do_not_call.value"
                    @input="setInput(['do_not_call', $event])"
                    class="basket-details__checkbox"
                >
                  <span class="checkbox__text">
                    <BaseSvg
                        class="checkbox__icon"
                        name="ico-mute"
                    />
                    {{ $t('Не дзвонити в двері') }}
                  </span>
                </BaseCheckbox>
                <BaseCheckbox v-if="false"
                              v-model="inputs.have_a_child.value"
                              @input="setInput(['have_a_child', $event])"
                              class="basket-details__checkbox"
                >
                  <span class="checkbox__text">
                    <BaseSvg
                        class="checkbox__icon"
                        name="ico-stroller"
                    />
                    {{ $t('У мене мала дитина (до 12 років)') }}
                  </span>
                </BaseCheckbox>
                <BaseCheckbox v-if="false"
                              v-model="inputs.have_a_dog.value"
                              @input="setInput(['have_a_dog', $event])"
                              class="basket-details__checkbox"
                >
                  <span class="checkbox__text">
                    <BaseSvg
                        class="checkbox__icon"
                        name="ico-animal-kingdom"
                    />
                    {{ $t('У мене є собака') }}
                  </span>
                </BaseCheckbox>
              </div>
            </div>
          </div>
          <div class="basket__form-btn">
            <button type="button"
                    class="base-btn"
                    @click.prevent="sendForm">
              {{ $t('Оформити замовлення') }}
              <BasePreloader v-if="pendingSend"/>
            </button>
          </div>
        </template>
      </div>
    </div>
    <template v-if="basket.length">
      <PopularBlock :title="$t('З цим купують')"
                    :products="relevantProducts"
                    :pending="pendingRelevant"
                    v-if="pendingRelevant || !pendingRelevant && relevantProducts.length > 0"/>
      <PopularBlock :title="$t('Суперпропозиція')"
                    :products="popularProducts"
                    :pending="pendingPopular"
                    v-if="pendingPopular || !pendingPopular && popularProducts.length > 0"/>
    </template>
  </div>
</template>

<script>
  import {http} from '@/axios'
  import {mapActions, mapGetters, mapState} from 'vuex'
  import BasketItem from '../components/BasketItem'
  import formMixins from '../mixins/formMixins'
  import getBasketItemPrice from '../helpers/getBasketItemPrice'
  import getBasketItemPackingPrice from '../helpers/getBasketItemPackingPrice'
  import AlertInline from '@/components/AlertInline'
  import {DELIVERY_METHOD_ADDRESS, DELIVERY_METHOD_SELF} from '@/constants/deliveryMethods'
  import formatPrice from '@/helpers/formatPrice'
  import getDiscountValue from '@/helpers/getDiscountValue'
  import getCategoryDiscountPercent from '@/helpers/getCategoryDiscountPercent'
  import formatTime from '@/helpers/formatTime'
  import formatDate from '@/helpers/formatDate'
  import PopularBlock from '@/components/PopularBlock'
  import Gifts from '@/components/Gifts'

  const FormBasket = () => import(/* webpackChunkName: "FormBasket" */ '../components/FormBasket')

  // const addressRequired = ['user_name', 'phone', 'city_id', 'street', 'house_number']
  // const selfRequired = ['user_name', 'phone', 'time_self', 'pizzeria_id']

  export default {
    mixins: [formMixins],
    name: 'Basket',
    components: {
      Gifts,
      PopularBlock,
      AlertInline,
      BasketItem,
      FormBasket,
    },
    data() {
      return {
        addressRequired: ['user_name', 'phone', 'city_id', 'street', 'house_number', 'email'],
        selfRequired: ['user_name', 'phone', 'pizzeria_id', 'email'],
        DELIVERY_METHOD_ADDRESS,
        DELIVERY_METHOD_SELF,
        pendingSend: false,
        pendingLastOrder: false,
        inputs: {
          city_id: {
            value: null
          },
          user_name: {
            value: null
          },
          phone: {
            value: null
          },
          email: {
            value: null
          },
          entrance: {
            value: null,
          },
          house_number: {
            value: null
          },
          apartment_number: {
            value: null,
          },
          street: {
            value: null
          },
          time: {
            value: null
          },
          time_self: {
            value: null
          },
          comment: {
            value: null,
          },
          delivery_type: {
            value: null,
          },
          payment_type: {
            value: null,
          },
          do_not_call: {
            value: false,
          },
          have_a_child: {
            value: false,
          },
          have_a_dog: {
            value: false,
          },
          use_bonuses: {
            value: false
          },
          pizzeria_id: {
            value: null,
          },
          created_with: {
            value: 'site'
          },
          devices_count: {
            value: 0
          },
          intercom_code: {
            value: null
          },
          sticks_count: {
            value: 0
          },
          student_sticks_count: {
            value: 0
          },
          add_oil: {
            value: false
          },
          gift_product: {
            value: null
          },
          quickly: {
            value: true
          }
        },
        selfPickingDiscountValue: 0,
        popularProducts: [],
        pendingPopular: false,
        relevantProducts: [],
        pendingRelevant: false,
        gifted_products: null
      }
    },
    computed: {
      ...mapState('basket', [
        'basket'
      ]),
      ...mapState('settings', ['settings']),
      ...mapState('user', [
        'profile',
        'pendingLoad'
      ]),
      ...mapGetters('user', ['isLogged']),
      disabledGifts() {
        const min_sum = Number(this.$get(this.settings, 'giftedproducts.min_order_sum', 0))
        const status = this.$get(this.settings, 'giftedproducts.status', false)
        if (!status) return true
        return min_sum > this.fullProductsPrice
      },
      withOil() {
        return this.basket.some(item => {
          return item.product.category.is_oil
        })
      },
      totalPackingPrice() {
        let totalPrice = 0
        this.basket.forEach((item) => {
          totalPrice += getBasketItemPackingPrice(item) * item.quantity
        })
        return totalPrice
      },
      productsPriceForShow() {
        let totalPrice = 0
        this.basket.forEach((item) => {
          let categoryDiscountPercent = getCategoryDiscountPercent(item.product.category, this.selectedTime)
          let categoryDiscountValue = getDiscountValue(getBasketItemPrice(item), categoryDiscountPercent)
          if (categoryDiscountPercent >= this.selfPickingDiscountPercent) {
            totalPrice += (getBasketItemPrice(item) - categoryDiscountValue) * item.quantity
          } else {
            totalPrice += getBasketItemPrice(item) * item.quantity
          }
        })
        return totalPrice
      },
      productsPrice() {
        let totalPrice = 0
        this.clearSelfPickingDiscountValue()
        this.basket.forEach((item) => {
          const allow_self_pickup_discount = !!this.$get(item.product.category, 'allow_self_pickup_discount', 0)
          const categoryDiscountPercent = getCategoryDiscountPercent(item.product.category, this.selectedTime)
          const categoryDiscountValue = getDiscountValue(getBasketItemPrice(item), categoryDiscountPercent)
          const selfPickingDiscountPercent = allow_self_pickup_discount ? this.selfPickingDiscountPercent : 0

          if (categoryDiscountPercent >= selfPickingDiscountPercent) {
            totalPrice += (getBasketItemPrice(item) - categoryDiscountValue) * item.quantity
          } else {
            const selfPickingDiscountValue = getDiscountValue(getBasketItemPrice(item), selfPickingDiscountPercent)
            this.addSelfPickingDiscountValue(selfPickingDiscountValue * item.quantity)
            totalPrice += (getBasketItemPrice(item) - selfPickingDiscountValue) * item.quantity
          }
        })
        return totalPrice
      },
      fullProductsPrice() {
        return this.productsPrice + this.totalPackingPrice
      },
      deliveryPrice() {
        if (!this.showByDelivery([DELIVERY_METHOD_ADDRESS])) return 0
        let delivery_price = this.$get(this.inputs.city_id.value, 'delivery_price', 0)
        let min_free_delivery_price = this.$get(this.inputs.city_id.value, 'min_free_delivery_price', null)

        if (min_free_delivery_price && this.fullProductsPrice >= +min_free_delivery_price) return 0
        return +delivery_price
      },
      totalPrice() {
        let sum = this.fullProductsPrice + this.deliveryPrice
        const selectedGift = this.$get(this.gifted_products, 'product_list', []).find(item => item.id === this.inputs.gift_product.value)
        if (selectedGift) {
          sum += selectedGift.variants[0].price
        }

        if (this.inputs.use_bonuses.value) {
          sum -= this.bonuses
        }

        return sum < 0 ? 0 : sum
      },
      minOrderPrice() {
        return Number(this.$get(this.inputs.city_id.value, 'min_order_price', 0))
      },
      selfPickingDiscountPercent() {
        const isActive = JSON.parse(JSON.stringify(this.$get(this.settings.orders, 'isSelfPickingDiscountActive', false)))
        const percent = +this.$get(this.settings.orders, 'selfPickingDiscount', 0)
        if (this.showByDelivery([DELIVERY_METHOD_SELF]) && isActive && percent > 0) {
          return percent
        }
        return 0
      },
      selectedTime() {
        return this.showByDelivery([DELIVERY_METHOD_ADDRESS]) ? this.inputs.time.value : this.inputs.time_self.value
      },
      bonuses() {
        const isActive = JSON.parse(JSON.stringify(this.$get(this.settings.orders, 'bonuses_available', false)))
        //return (bonuses >= (this.fullProductsPrice + this.deliveryPrice)) ? bonuses : 0
        return isActive && this.$get(this.profile, 'available_bonuses') || 0
      },
      disabledBonuses() {
        return this.bonuses < (this.fullProductsPrice + this.deliveryPrice)
      },
      usedBonuses() {
        let sum = this.fullProductsPrice + this.deliveryPrice

        if (this.inputs.use_bonuses.value) {
          return sum > this.bonuses ? this.bonuses : sum
        }

        return 0
      },
      hasProductsOutsideDeliveryTime() {
        if (!this.selectedTime) return false

        const deliveryTime = new Date(this.selectedTime)

        if (!deliveryTime) return false

        return this.basket.some(item => {
          let from = this.$get(item.product.category, 'business_lunch_from', null)
          let to = this.$get(item.product.category, 'business_lunch_to', null)

          if (!from || !to) return false

          let dateFrom = new Date(`0000-01-01T${from}:00`)
          let dateTo = new Date(`0000-01-01T${to}:00`)

          deliveryTime.setFullYear(0)
          deliveryTime.setMonth(0)
          deliveryTime.setDate(1)

          return deliveryTime < dateFrom || deliveryTime > dateTo
        })
      },
      hasProductsOutsideWorkingDays() {
        if (!this.selectedTime) return false

        const deliveryTime = new Date(this.selectedTime)

        if (!deliveryTime) return false

        return this.basket.some(item => {
          let working_days = this.$get(item.product.category, 'working_days', [])

          let day = deliveryTime.getDay()

          return !working_days.some(item => item === day)
        })
      },
      productIdList() {
        return this.basket.reduce((res, item) => {
          const id = item.product.id
          if (res.includes(id)) return res
          res.push(item.product.id)
          return res
        }, [])
      },
      categoriesIdList() {
        return this.basket.reduce((res, item) => {
          const id = item.product.category.id
          if (res.includes(id)) return res
          res.push(item.product.category.id)
          return res
        }, [])
      }
    },
    methods: {
      ...mapActions('basket', ['clearBasket']),
      addSelfPickingDiscountValue(value) {
        this.selfPickingDiscountValue += value
      },
      clearSelfPickingDiscountValue() {
        this.selfPickingDiscountValue = 0
      },
      showByDelivery(types) {
        const id = this.$get(this.inputs.delivery_type.value, 'id')
        return id && types.includes(id)
      },
      setInput([key, value]) {
        this.inputs[key].value = value
        if (this.inputs[key].error) {
          this.inputs[key].error = null
        }
      },
      collectData() {
        const sendData = this.createSendDataMix(this.inputs)
        if (!sendData) return null

        if (this.showByDelivery([DELIVERY_METHOD_SELF])) {
          sendData.city_id = null
          sendData.street = null
          sendData.house = null
          sendData.entrance = null
          sendData.apartment_number = null
          sendData.do_not_call = null
          sendData.have_a_child = null
          sendData.have_a_dog = null
          sendData.time = sendData.time_self
          delete sendData.time_self
        } else if (this.showByDelivery([DELIVERY_METHOD_ADDRESS])) {
          sendData.pizzeria_id = null
          delete sendData.time_self
        }

        if (sendData.time) {
          sendData.time = sendData.time.toLocaleString('uk-UA')
        }

        for (let key in sendData) {
          if (sendData.hasOwnProperty(key) && sendData[key] === null) {
            delete sendData[key]
          }
        }

        sendData.cart = this.basket.map((item) => {
          const additional_ingredients = []

          for (const key in item.additional_ingredients) {
            const el = item.additional_ingredients[key]

            additional_ingredients.push({
              ingredient_id: el.ingredient.id,
              quantity: el.quantity,
            })
          }

          return {
            type: 'product',
            product_id: item.product.id,
            size: item.variant.size,
            quantity: item.quantity,
            sauce_id: item.property && item.property.id || null,
            comment: item.comment,
            main_ingredients: additional_ingredients.length && additional_ingredients || null
          }
        })

        return sendData
      },
      sendForm() {
        this.resetErrorsMix()
        this.setRequiredFields()

        //min delivery price
        if (
            this.showByDelivery([DELIVERY_METHOD_ADDRESS])
            && this.minOrderPrice > this.fullProductsPrice
        ) {
          this.$vModal.open('message', {
            title: this.$t('Увага!'),
            message: `${this.$t('Мінімальна сума замовлення для Вашого населеного пункту становить')} ${this.minOrderPrice | formatPrice()} ${this.$t('грн')}`
          })

          return
        }

        //working days range for category
        if (this.hasProductsOutsideWorkingDays) {
          const selectedTime = formatDate(this.selectedTime)
          let dayName = this.selectedTime.toLocaleDateString(this.$i18n.locale, {weekday: 'long'})

          this.$vModal.open('message', {
            title: `${this.$t('Замовлення не може бути виконане в цей день')}: ${dayName}, ${selectedTime}`,
            message: this.$t('Виберіть інший день або видаліть товари з недоступними днями.')
          })
          return
        }


        //delivery time range for category
        if (this.hasProductsOutsideDeliveryTime) {
          const selectedTime = formatTime(this.selectedTime)
          this.$vModal.open('message', {
            title: `${this.$t('Замовлення не може бути виконане в цей час')}: ${selectedTime}`,
            message: this.$t('Виберіть інший час або видаліть товари з обмеженим часом.')
          })
          return
        }
        console.log('collect')
        const data = this.collectData()
        console.log('collect2')
        if (!data) return

        this.pendingSend = true

        http.post('/api/order/create', data).then((data) => {

          //eslint-disable-next-line no-undef
          if (fbq) {
            //eslint-disable-next-line no-undef
            fbq('track', 'Purchase', {
              currency: 'UAH',
              value: this.totalPrice
            })
          }

          if (data.data.payment_url) {
            this.$router.push({name: 'home'})
            window.location.href = data.data.payment_url
            this.clearBasket()
            return
          }
          this.clearBasket()
          this.$router.push({name: 'home'})
          this.$vModal.open('message', {
            title: this.$t('Оформлення замовлення'),
            message: data.data.message
          })
        }).catch((error) => {
          this.handlerErrorMix(error, this.inputs)
        }).finally(() => {
          this.pendingSend = false
        })
      },
      setRequiredFields() {
        const variants = this.inputs.delivery_type.value && this.showByDelivery([DELIVERY_METHOD_ADDRESS])
            ? this.addressRequired
            : this.selfRequired

        for (const key in this.inputs) {
          if (variants.includes(key)) {
            this.$set(this.inputs[key], 'required', true)
          } else {
            this.$set(this.inputs[key], 'required', false)
          }
        }
      },
      getLastOrder() {
        this.pendingLastOrder = true
        return http.get('/api/user/last-order-address').then(({data}) => {
          const order = data.item
          for (const key in this.inputs) {
            if (order.hasOwnProperty(key)) {
              const value = order[key]

              if (value) {
                switch (key) {
                  //ignore list
                  case 'quickly':
                    break
                  //set false list
                  case 'do_not_call':
                  case 'have_a_child':
                  case 'have_a_dog':
                    this.inputs[key].value = !!value
                    break
                  default:
                    this.inputs[key].value = value
                    break
                }
              }
            }
          }
        }).finally(() => {
          this.pendingLastOrder = false
        })
      },
      loadPopularProducts() {
        if (!this.categoriesIdList || !this.categoriesIdList.length) return
        this.pendingPopular = true
        return http.get(`/api/top-products?categories=${this.categoriesIdList.join(',')}`)
            .then(({data}) => {
              this.popularProducts = data.data
            }).finally(() => {
              this.pendingPopular = false
            })
      },
      loadRelevantProducts() {
        if (!this.productIdList || !this.productIdList.length) return
        this.pendingRelevant = true
        return http.get(`/api/with-this-buy?products=${this.productIdList.join(',')}`)
            .then(({data}) => {
              this.relevantProducts = data.data
            }).finally(() => {
              this.pendingRelevant = false
            })
      },
    },
    mounted() {
      if (this.isLogged) {
        this.$store.dispatch('user/load').then((profile) => {
          if (profile.gifted_products) {
            this.gifted_products = profile.gifted_products
          }
          if (profile.name) {
            this.setInput(['user_name', profile.name])
          }
          if (profile.phone) {
            this.setInput(['phone', profile.phone.replace(/^380|^\+380|^0/g, '')])
          }
        })

        this.getLastOrder()
      }

      this.loadPopularProducts()
      this.loadRelevantProducts()
    },
    watch: {
      'inputs.delivery_type.value'() {
        this.resetErrorsMix()
        this.setRequiredFields()
      },
      selfPickingDiscountPercent(to) {
        if (!to) {
          this.clearSelfPickingDiscountValue()
        }
      },
      disabledBonuses(to) {
        if (to) {
          this.setInput(['use_bonuses', false])
        }
      },
      'inputs.quickly.value': {
        immediate: true,
        handler(to) {
          const timeFieldIndex = this.addressRequired.findIndex(item => item === 'time')
          if (to) {
            if (timeFieldIndex >= 0) {
              this.addressRequired.splice(timeFieldIndex, 1)
            }
          } else if (timeFieldIndex < 0) {
            this.addressRequired.push('time')
          }
        }
      }
    }
  }
</script>

