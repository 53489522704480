<template>
  <div class="buy-product">
    <BaseSvg
      name="ico-basket"
      width="10rem"
      height="10rem"
      class="buy-product__icon"
    />
    <div class="buy-product__content">
      <h3 class="buy-product__title">
        Замовлення успішно оформлено
      </h3>
      <p class="buy-product__text">
        Очікуйте дзвінок від кур'єра
      </p>
    </div>
  </div>
</template>

<script>
    export default {
        name: "BuyProductModal"
    }
</script>
