export default {
  data() {
    return {
      overallErrorsMix: null,
    }
  },
  methods: {
    createSendDataMix(inputs) {
      const data = {}

      const moderationResult = this.moderateInputs(inputs)
      if(!moderationResult) {
        return this.addOverallErrorMix(this.$t('Заповніть всі обовязкові поля'))
      }

      for (let key in inputs) {
        let input = inputs[key]
        if (typeof input.value === 'string' && !input.value.trim()) {
          input.value = null
        }
        data[key] = input.value && input.value.id ? input.value.id : input.value
      }

      return data
    },
    handlerErrorMix(errorObj, inputs) {
      if (!errorObj.response || !errorObj.response.data) {
        return this.addOverallErrorMix(errorObj.message)
      }

      if (!errorObj.response.data.errors && !errorObj.response.data.message) {
        return this.addOverallErrorMix(errorObj.message)
      }

      if (errorObj.response.data.errors) {
        if (inputs) {
          for (let key in errorObj.response.data.errors) {
            if (inputs.hasOwnProperty(key)) {
              this.$set(inputs[key], 'error', errorObj.response.data.errors[key])
            } else {
              this.addOverallErrorMix(errorObj.response.data.errors[key])
            }
          }
        } else {
          this.addOverallErrorMix(Object.values(errorObj.response.data.errors))
        }
      }

      if (errorObj.response.data.message) {
        this.addOverallErrorMix(errorObj.response.data.message)
      }
    },
    moderateInputs(inputs) {
      let hasError = false

      for(const key in inputs) {
        const input = inputs[key]

        if (input.required && !input.value) {
          this.$set(input, 'error', this.$t("Обов'язкове поле"))
          hasError = true
        }
      }

      return !hasError
    },
    resetErrorsMix(inputs) {
      this.overallErrorsMix = null

      // - -

      if (!inputs) inputs = this.inputs
      if (!inputs) return

      for (let key in inputs) {
        this.$set(inputs[key], 'error', null)
      }
    },
    addOverallErrorMix(errors) {
      if(!errors) return

      if(!this.overallErrorsMix) {
        this.overallErrorsMix = []
      }

      if(Array.isArray(errors)) {
        if(errors.length) {
          this.overallErrorsMix = this.overallErrorsMix.concat(errors)
        }
      } else {
        this.overallErrorsMix.push(errors)
      }

      if(!this.overallErrorsMix.length) {
        this.overallErrorsMix = null
      }
    }
  }
}
