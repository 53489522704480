<template>
  <div class="gifts">
    <div class="gifts__title">
      <BaseSvg name="ico-gift"/>
      {{ $t('Обери подарунок') }}</div>
    <div class="gifts__carousel-wrap">
      <div ref="carousel"
           class="gifts__carousel">
        <div class="gifts__list">
          <div v-for="(gift, index) in giftedProducts.product_list"
               :key="gift.id"
               :class="{'_active': selectedId === gift.id, '_disabled': computedDisabled}"
               class="gifts__item">
            <div class="gifts__item-wrap">
              <div class="gifts__item-inner"
                   @click.prevent="onSelect(gift.id, index)">
                <div class="gifts__item-img-wrap">
                  <img :src="$imgPlaceholder"
                       :data-src="gift.image_xs"
                       :alt="gift.name"
                       class="lazyload"
                  >
                </div>
                <div class="gifts__item-label">{{ gift.name }}</div>
                <div class="gifts__item-price _small">{{ gift.variants[0].weight }}</div>
                <div class="gifts__item-price">{{ gift.variants[0].price | formatPrice }} {{ $t('грн') }}
                </div>
              </div>
              <button v-if="selectedId === gift.id"
                      type="button"
                      class="gifts__item-remove"
                      @click.prevent.stop="onSelect(null)"
              >
                <BaseSvg name="ico-tick"/>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div ref="scroll" class="gifts__scroll"></div>
    </div>
    <div class="gifts__text">
      {{ $t('Замов на суму більше {sum} грн і обирай подарунок', {sum: formatPrice($get(settings, 'giftedproducts.min_order_sum', 0))}) }}.
      <br>
      {{ $t('Залишилось часу') }}: <Countdown :time="timeLeft"/>
    </div>
  </div>
</template>

<script>
  import Swiper, {Scrollbar, Mousewheel} from 'swiper/core'
  import {mapState} from 'vuex'
  import formatPrice from '@/helpers/formatPrice';
  import Countdown from '@/components/Countdown'

  Swiper.use([Scrollbar, Mousewheel])

  export default {
    name: 'Gifts',
    components: {Countdown},
    model: {
      event: 'selectId',
      prop: 'selectedId'
    },
    props: {
      giftedProducts: null,
      selectedId: null,
      disabled: Boolean
    },
    data() {
      return {
        swiper: null,
        formatPrice
      }
    },
    computed: {
      ...mapState('settings', ['settings']),
      computedSelected: {
        get() {
          return this.selectedId
        },
        set(id) {
          this.$emit('selectId', id)
        }
      },
      timeLeft() {
        const expires_at = new Date(this.$get(this.giftedProducts, 'expires_at', new Date()))
        const now = new Date()
        return expires_at - now
      },
      isTimeOver() {
        return this.timeLeft <= 0
      },
      computedDisabled() {
        return this.disabled || this.isTimeOver
      }
    },
    watch: {
      computedDisabled: {
        immediate: true,
        handler(to) {
          if (to) {
            this.computedSelected = null
          }
        }
      }
    },
    methods: {
      createSwiper() {
        this.swiper = new Swiper(this.$refs['carousel'], {
          wrapperClass: 'gifts__list',
          slideClass: 'gifts__item',
          slidesPerView: 'auto',
          watchOverflow: true,
          roundLengths: true,
          centerInsufficientSlides: true,
          speed: 600,
          observer: true,
          threshold: 2,
          watchSlidesProgress: true,
          watchSlidesVisibility: true,
          observeParents: true,
          observeSlideChildren: true,
          freeMode: true,
          mousewheel: {
            forceToAxis: true
          },
          scrollbar: {
            el: this.$refs['scroll'],
            dragClass: 'gifts__scroll-drag',
            lockClass: '_lock',
            draggable: true,
            snapOnRelease: false
          },
        })
      },
      onSelect(id, index) {
        if (this.computedSelected === id) {
          this.computedSelected = null
        } else {
          this.computedSelected = id

          if (index) {
            if (!this.swiper) return
            this.swiper.slideTo(index)
          }
        }
      }
    },
    mounted() {
      this.createSwiper()
    },
    beforeDestroy() {
      if (this.swiper) {
        setTimeout(() => {
          this.swiper.destroy()
        }, 1000)
      }
    }
  }
</script>

<style>
</style>