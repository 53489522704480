import i18n from '@/i18n'

export default function (day) {
  day = +day
  if (isNaN(day)) return

  if (day < 0 || day > 7) return

  let days = {
    uk: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
  }
  if (days.hasOwnProperty(i18n.locale)) {
    return days[i18n.locale][day]
  }

  return days.en[day]
}