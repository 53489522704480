<template>
  <transition name="fade">
    <div v-if="withWeight || withTimeRange || withDaysRange || withRestaurantsRange"
         class="basket-item__labels">
      <div v-if="withWeight"
           class="basket-item__label">
        {{ $t('Вагова страва') }}
      </div>
      <div v-if="withTimeRange"
           class="basket-item__label">
        {{ $t('Доступно тільки з') }}
        {{ basketItem.product.category.business_lunch_from }}
        {{ $t('до') }}
        {{ basketItem.product.category.business_lunch_to }}
      </div>
      <div v-if="withDaysRange"
           class="basket-item__label">
        {{ $t('Доступно тільки у') }}:
        <span v-for="(day, index) in workingDays"
              :key="index">
          {{ getDayName(day) }}<template v-if="index < workingDays.length - 1">, </template>
        </span>
      </div>
      <div v-if="withRestaurantsRange"
           class="basket-item__label">
        {{ $t('Самовивіз тільки з') }}:
        <div v-for="(item, index) in pickup_restaurants"
             :key="index">
          {{ item.name }}<template v-if="index < pickup_restaurants.length - 1">, </template>
        </div>
      </div>
      <div v-if="withoutSelfPickingDiscount"
           class="basket-item__label">
        {{ $t('Знижка на самовивіз не діє') }}
      </div>
    </div>
  </transition>

</template>

<script>
  import getDayName from '@/helpers/getDayName'
  import {mapGetters} from 'vuex'
  export default {
    name: 'BasketItemLabels',
    props: {
      basketItem: Object,
      isSelfPicking: Boolean
    },
    data() {
      return {
        getDayName
      }
    },
    computed: {
      ...mapGetters('restaurant', ['restaurantsCount']),
      workingDays() {
        let days = this.$get(this.basketItem.product.category, 'working_days', [])
        if (days[0] === 0) {
          days.splice(0, 1)
          days.push(0)
        }
        return days
      },
      withWeight() {
        return this.basketItem.product.weight_dish
      },
      withTimeRange() {
        return this.$get(this.basketItem.product.category, 'business_lunch_from', null)
            || this.$get(this.basketItem.product.category, 'business_lunch_to', null)
      },
      withDaysRange() {
        return this.workingDays.length < 7
      },
      pickup_restaurants() {
        return this.$get(this.basketItem, 'product.category.pickup_restaurants', [])
      },
      withRestaurantsRange() {
        return this.isSelfPicking && this.pickup_restaurants && (this.pickup_restaurants.length < this.restaurantsCount)
      },
      withoutSelfPickingDiscount() {
        return this.isSelfPicking && !this.$get(this.basketItem.product.category, 'allow_self_pickup_discount', 0)
      }
    }
  }
</script>

<style lang="less">

</style>