<template>
  <div class="countdown">
    <span class="countdown__item" v-if="showDays">
      <span class="countdown__item-val">{{ days }}</span> {{ $t('дн') }}. </span>
    <span class="countdown__item" v-if="showHours">
      <span class="countdown__item-val">{{ hours }}</span> {{ $t('год') }}. </span>
    <span class="countdown__item">
      <span class="countdown__item-val">{{ String(minutes).padStart(2, '0') }}</span> {{ $t('хв') }}. </span>
    <span class="countdown__item">
      <span class="countdown__item-val">{{ String(seconds).padStart(2, '0') }}</span> {{ $t('сек') }}. </span>
  </div>
</template>

<script>
  const MILLISECONDS_SECOND = 1000
  const MILLISECONDS_MINUTE = 60 * MILLISECONDS_SECOND
  const MILLISECONDS_HOUR = 60 * MILLISECONDS_MINUTE
  const MILLISECONDS_DAY = 24 * MILLISECONDS_HOUR

  export default {
    name: 'Countdown',
    props: {
      canShrink: Boolean,
      autoStart: {
        type: Boolean,
        default: true,
      },
      time: {
        type: Number,
        default: 0,
      },
      interval: {
        type: Number,
        default: 1000,
      },
      now: {
        type: Function,
        default: () => Date.now(),
      },
    },
    data() {
      return {
        counting: false,
        endTime: 0,
        totalMilliseconds: 0,
        requestId: 0,
      }
    },
    computed: {
      days() {
        return Math.floor(this.totalMilliseconds / MILLISECONDS_DAY)
      },
      hours() {
        return Math.floor((this.totalMilliseconds % MILLISECONDS_DAY) / MILLISECONDS_HOUR)
      },
      minutes() {
        return Math.floor((this.totalMilliseconds % MILLISECONDS_HOUR) / MILLISECONDS_MINUTE)
      },
      seconds() {
        return Math.floor((this.totalMilliseconds % MILLISECONDS_MINUTE) / MILLISECONDS_SECOND)
      },
      milliseconds() {
        return Math.floor(this.totalMilliseconds % MILLISECONDS_SECOND)
      },
      totalDays() {
        return this.days
      },
      totalHours() {
        return Math.floor(this.totalMilliseconds / MILLISECONDS_HOUR)
      },
      totalMinutes() {
        return Math.floor(this.totalMilliseconds / MILLISECONDS_MINUTE)
      },
      totalSeconds() {
        return Math.floor(this.totalMilliseconds / MILLISECONDS_SECOND)
      },
      showDays() {
        if (!this.canShrink) return true
        return this.days > 0
      },
      showHours() {
        if (!this.canShrink || this.showDays) return true
        return this.hours > 0
      },
    },
    watch: {
      $props: {
        deep: true,
        immediate: true,
        handler() {
          this.totalMilliseconds = this.time
          this.endTime = this.now() + this.time

          if (this.autoStart) {
            this.start()
          }
        },
      },
      totalMilliseconds: {
        immediate: true,
        handler(to) {
          this.$emit('updateTotal', to)
        }
      }
    },
    mounted() {
      document.addEventListener('visibilitychange', this.handleVisibilityChange)
    },

    beforeUnmount() {
      document.removeEventListener('visibilitychange', this.handleVisibilityChange)
      this.pause()
    },
    methods: {
      start() {
        if (this.counting) {
          return
        }
        this.counting = true
        if (document.visibilityState === 'visible') {
          this.continue()
        }
      },
      continue() {
        if (!this.counting) {
          return
        }

        const delay = Math.min(this.totalMilliseconds, this.interval)

        if (delay > 0) {
          let init
          let prev
          const step = (now) => {
            if (!init) {
              init = now
            }

            if (!prev) {
              prev = now
            }

            const range = now - init

            if (
                range >= delay
                || range + ((now - prev) / 2) >= delay
            ) {
              this.progress()
            } else {
              this.requestId = requestAnimationFrame(step)
            }

            prev = now
          }

          this.requestId = requestAnimationFrame(step)
        } else {
          this.end()
        }
      },
      pause() {
        cancelAnimationFrame(this.requestId)
      },
      progress() {
        if (!this.counting) {
          return
        }

        this.totalMilliseconds -= this.interval

        this.continue()
      },
      abort() {
        if (!this.counting) {
          return
        }

        this.pause()
        this.counting = false
      },
      end() {
        if (!this.counting) {
          return
        }

        this.pause()
        this.totalMilliseconds = 0
        this.counting = false
      },
      update() {
        if (this.counting) {
          this.totalMilliseconds = Math.max(0, this.endTime - this.now())
        }
      },
      handleVisibilityChange() {
        switch (document.visibilityState) {
          case 'visible':
            this.update()
            this.continue()
            break

          case 'hidden':
            this.pause()
            break

          default:
        }
      },
    }
  }
</script>

<style>
</style>